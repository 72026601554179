import Vue from 'vue'
import { isPlainObject, cloneDeep, omit, isEmpty } from 'lodash'
import { loadProgressBar } from '@/common/utils/axiosProgressBar.js'

export { loadProgressBar }

export function valueToTextValueObject (v) {
  return isPlainObject(v) && 'text' in v
    ? v
    : {
        text: v,
        value: v
      }
}

// vuex store utils

export function reindexObjectArray (objects, defaultObj = {}) {
  if (objects.length === 0) objects.push({ ...defaultObj, id: 0 })
  else {
    objects.forEach((e, i) => Object.assign(e, Object.assign({ ...defaultObj }, e, { id: i })))
  }
}

export function rerankObjectArray (objects = [], defaultObj = {}) {
  objects.forEach((e, i) => Object.assign(e, Object.assign({}, defaultObj, e, { rank: i + 1 })))
}

export function ensureRankedObjectArray (obj, prop, defaultObj = {}) {
  if (isEmpty(obj[prop])) {
    obj[prop] = [Object.assign({}, defaultObj, { rank: 1 })]
  } else {
    rerankObjectArray(obj[prop], defaultObj)
  }
}

export function createAddToFn (container, defaultElem) {
  const stateName = String(container)
  const dClone = s => Object.assign({}, cloneDeep(defaultElem), s)

  return (state, payload = { item: {}, index: -1 }) => {
    let { item, index } = payload
    index = index > -1 ? index : state[stateName].length

    Vue.$log.debug(`add to [${container}] @ index`, index)

    // prevent short-timed existance of same rank
    const substate = [...state[stateName]]
    substate.splice(index, 0, omit(dClone(item), 'id'))
    rerankObjectArray(substate)
    state[stateName] = substate

    state._meta.dirty = true
  }
}

export function createRemoveFromFn (container) {
  const stateName = String(container)
  return (state, { id, rank }) => {
    Vue.$log.debug(`remove from [${container}]`, { id, rank })
    state[stateName].splice(rank - 1, 1)
    rerankObjectArray(state[stateName])
    state._meta.dirty = true
  }
}

export function createSetFn (container) {
  const stateName = String(container)
  return (state, elem) => {
    const entries = state[stateName]

    const toUpdateIndex = entries.findIndex(t => t.rank === elem.rank)

    Vue.$log.debug(`set  [${container}][${toUpdateIndex}]`)

    const toUpdate = entries[toUpdateIndex]
    entries.splice(toUpdateIndex, 1, {
      ...toUpdate,
      ...elem
    })
    state._meta.dirty = true
  }
}
